/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ExtraSmallOnly, HideExtraSmall, TYPE } from 'theme'
import { DarkGreyCard } from 'components/Card'
import { TokenData } from '../../state/tokens/reducer'
import Loader, { LoadingRows } from 'components/Loader'
import { Link } from 'react-router-dom'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { RowFixed } from 'components/Row'
import { formatDollarAmount } from 'utils/numbers'
import Percent from 'components/Percent'
import { Label, ClickableText } from '../Text'
import { PageButtons, Arrow, Break } from 'components/shared'
import HoverInlineText from '../HoverInlineText'
import useTheme from 'hooks/useTheme'
import { TOKEN_HIDE } from '../../constants/index'

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 20px 3fr repeat(4, 1fr);

  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 1.5fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 20px 1.5fr repeat(2, 1fr);
    & :nth-child(6) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(2, 1fr);
    > *:first-child {
      display: none;
    }
    > *:nth-child(3) {
      display: none;
    }
  }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const ResponsiveLogo = styled(CurrencyLogo)`
  @media screen and (max-width: 670px) {
    width: 16px;
    height: 16px;
  }
`

const DataRow = ({ tokenData, index }: { tokenData: TokenData; index: number }) => {
  const theme = useTheme()
  return (
    <LinkWrapper to={'tokens/' + tokenData.address}>
      <ResponsiveGrid>
        <Label>{index + 1}</Label>
        <Label>
          <RowFixed>
            <ResponsiveLogo address={tokenData.address} />
          </RowFixed>
          <ExtraSmallOnly style={{ marginLeft: '6px' }}>
            <Label ml="8px">{tokenData.symbol}</Label>
          </ExtraSmallOnly>
          <HideExtraSmall style={{ marginLeft: '10px' }}>
            <RowFixed>
              <HoverInlineText text={tokenData.name} />
              <Label ml="8px" color={theme.text3}>
                ({tokenData.symbol})
              </Label>
            </RowFixed>
          </HideExtraSmall>
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(tokenData.priceUSD)}
          {/* {formatDollarAmount(Math.random() * (16500 - 1) + 1)} */}
        </Label>
        <Label end={1} fontWeight={400}>
          <Percent value={tokenData.priceUSDChange} fontWeight={400} />
          {/* <Percent value={Math.random() * (10 + 0.01) - 0.01} fontWeight={400} /> */}
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(tokenData.volumeUSD)}
          {/* {formatDollarAmount(Math.random() * (350000000 - 0) + 0)} */}
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(tokenData.tvlUSD)}
          {/* {formatDollarAmount(Math.random() * (990000000 - 1000000) + 1000000)} */}
        </Label>
      </ResponsiveGrid>
    </LinkWrapper>
  )
}

const SORT_FIELD = {
  name: 'name',
  volumeUSD: 'volumeUSD',
  tvlUSD: 'tvlUSD',
  priceUSD: 'priceUSD',
  priceUSDChange: 'priceUSDChange',
  priceUSDChangeWeek: 'priceUSDChangeWeek',
}

const MAX_ITEMS = 10

export default function TokenTable({
  tokenDatas,
  maxItems = MAX_ITEMS,
}: {
  tokenDatas: TokenData[] | undefined
  maxItems?: number
}) {
  // theming
  const theme = useTheme()

  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.tvlUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  // const [SortedTokensCustom, setSortedTokensCustom] = useState([])

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    // const date = new Date()
    // date.setTime(date.getTime()+(1*24*60*60*1000))
    // const expires = date.toUTCString()

    // const decodedCookie = decodeURIComponent(document.cookie);
    // if(decodedCookie.includes("sortedTokens")) {
    //   const decodedCookieArr = decodedCookie.split('; ')
    //   decodedCookieArr.forEach(decodedCookieArr_ => {
    //     const cookieName = decodedCookieArr_.split('=')
    //     if(cookieName[0] === "sortedTokens") {
    //       let temp = window.localStorage.getItem("sortedTokens");
    //       if(temp !== null) {
    //         setSortedTokensCustom(JSON.parse(atob(temp)));
    //       }
    //     }
    //   });
    // }
    // else {
    //   let sortedTokensTemp = sortedTokens.map(sortedTokens_ => {
    //     let priceUSD = Math.random() * (20900 - 0.00000001) + 0.00000001;
    //     let priceUSDChange = Math.random() * (55 + 8) - 8;
    //     let volumeUSD = Math.random() * (937500 - 500) + 500;
    //     let volumeUSDChange = Math.random() * (90 - 0.05) + 0.05;
    //     let volumeUSDWeek = Math.random() * (937500 - 500) + 500;
    //     let tvlUSD = Math.random() * (68231200 - 34100) + 34100;
    //     let feesUSD = Math.random() * (68231200 - 34100) + 34100;
    //     let tvlUSDChange = Math.random() * (90 - 0.05) + 0.05;
    //     switch (sortedTokens_.symbol) {
    //       case "USDT":
    //         priceUSD = Math.random() * (1 - 0.00000001) + 0.00000001;
    //         priceUSDChange = Math.random() * (0.10 + 0.01) - 0.01;
    //         volumeUSD = Math.random() * (796700 - 796600) + 796600;
    //         volumeUSDChange = Math.random() * (85 - 75) + 75;
    //         volumeUSDWeek = Math.random() * (796700 - 796600) + 796600;
    //         tvlUSD = Math.random() * (54585000 - 54584900) + 54584900;
    //         feesUSD = Math.random() * (54585000 - 54584900) + 54584900;
    //         tvlUSDChange = Math.random() * (85 - 75) + 75;
    //         break;

    //       case "WETH":
    //         priceUSD = Math.random() * (1550 - 1525) + 1525;
    //         priceUSDChange = Math.random() * (0.50 + 0.01) - 0.01;
    //         volumeUSD = Math.random() * (937500 - 937400) + 937400;
    //         volumeUSDChange = Math.random() * (95 - 85) + 85;
    //         volumeUSDWeek = Math.random() * (937500 - 937400) + 937400;
    //         tvlUSD = Math.random() * (68231200 - 68231100) + 68231100;
    //         feesUSD = Math.random() * (68231200 - 68231100) + 68231100;
    //         tvlUSDChange = Math.random() * (95 - 85) + 85;
    //         break;

    //       case "USDC":
    //         priceUSD = Math.random() * (1 - 0.00000001) + 0.00000001;
    //         priceUSDChange = Math.random() * (0.10 + 0.01) - 0.01;
    //         volumeUSD = Math.random() * (861300 - 861200) + 861200;
    //         volumeUSDChange = Math.random() * (95 - 85) + 85;
    //         volumeUSDWeek = Math.random() * (861300 - 861200) + 861200;
    //         tvlUSD = Math.random() * (61408100 - 61408000) + 61408000;
    //         feesUSD = Math.random() * (61408100 - 61408000) + 61408000;
    //         tvlUSDChange = Math.random() * (95 - 85) + 85;
    //         break;

    //       case "FRAX":
    //         priceUSD = Math.random() * (1 - 0.00000001) + 0.00000001;
    //         priceUSDChange = Math.random() * (55 - 45) + 45;
    //         volumeUSD = Math.random() * (24700 - 24600) + 24600;
    //         volumeUSDChange = Math.random() * (5 - 1) + 1;
    //         volumeUSDWeek = Math.random() * (24700 - 24600) + 24600;
    //         tvlUSD = Math.random() * (2047000 - 2046900) + 2046900;
    //         feesUSD = Math.random() * (2047000 - 2046900) + 2046900;
    //         tvlUSDChange = Math.random() * (5 - 1) + 1;
    //         break;
          
    //       case "DAI":
    //         priceUSD = Math.random() * (1 - 0.00000001) + 0.00000001;
    //         priceUSDChange = Math.random() * (0.10 + 0.01) - 0.01;
    //         volumeUSD = Math.random() * (244900 - 244800) + 244800;
    //         volumeUSDChange = Math.random() * (25 - 15) + 15;
    //         volumeUSDWeek = Math.random() * (244900 - 244800) + 244800;
    //         tvlUSD = Math.random() * (13646300 - 13646200) + 13646200;
    //         feesUSD = Math.random() * (13646300 - 13646200) + 13646200;
    //         tvlUSDChange = Math.random() * (25 - 15) + 15;
    //         break;

    //       case "WBTC":
    //         priceUSD = Math.random() * (20900 - 20800) + 20800;
    //         priceUSDChange = Math.random() * (0.30 + 0.01) - 0.01;
    //         volumeUSD = Math.random() * (805300 - 805200) + 805200;
    //         volumeUSDChange = Math.random() * (75 - 65) + 65;
    //         volumeUSDWeek = Math.random() * (805300 - 805200) + 805200;
    //         tvlUSD = Math.random() * (47761900 - 47761800) + 47761800;
    //         feesUSD = Math.random() * (47761900 - 47761800) + 47761800;
    //         tvlUSDChange = Math.random() * (75 - 65) + 65;
    //         break;

    //       default:
    //         break;
    //     }

    //     return Object.assign({}, sortedTokens_, {exists: sortedTokens_.exists,
    //     address: sortedTokens_.address,
    //     name: sortedTokens_.name,
    //     symbol: sortedTokens_.symbol,
    //     volumeUSD: volumeUSD,
    //     volumeUSDChange: volumeUSDChange,
    //     volumeUSDWeek: volumeUSDWeek,
    //     txCount: sortedTokens_.txCount,
    //     tvlUSD: tvlUSD,
    //     feesUSD: feesUSD,
    //     tvlUSDChange: tvlUSDChange,
    //     tvlToken: sortedTokens_.tvlToken,
    //     priceUSD: priceUSD,
    //     priceUSDChange: priceUSDChange,
    //     priceUSDChangeWeek: sortedTokens_.priceUSDChangeWeek});
    //   });
    //   if(sortedTokensTemp.length > 0) {
    //     window.localStorage.removeItem("sortedTokens");
    //     window.localStorage.setItem("sortedTokens", btoa(JSON.stringify(sortedTokensTemp)));
    //     document.cookie = "sortedTokens=true; expires="+expires;
    //   }
    //   let sortedTokensTemp2 = JSON.stringify(sortedTokensTemp);
    //   setSortedTokensCustom(JSON.parse(sortedTokensTemp2));
    // }


    let extraPages = 1
    if (tokenDatas) {
      if (tokenDatas.length % maxItems === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(tokenDatas.length / maxItems) + extraPages)
    }
  }, [maxItems, tokenDatas])

  const sortedTokens = useMemo(() => {
    return tokenDatas
      ? tokenDatas
          .filter((x) => !!x && !TOKEN_HIDE.includes(x.address))
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof TokenData] > b[sortField as keyof TokenData]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [tokenDatas, maxItems, page, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!tokenDatas) {
    return <Loader />
  }

  return (
    <Wrapper>
      {sortedTokens.length > 0 ? (
      // {SortedTokensCustom.length > 0 ? (
        <AutoColumn gap="16px">
          <ResponsiveGrid>
            <Label color={theme.text2}>#</Label>
            <ClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.name)}>
              Name {arrow(SORT_FIELD.name)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.priceUSD)}>
              Price {arrow(SORT_FIELD.priceUSD)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.priceUSDChange)}>
              Price Change {arrow(SORT_FIELD.priceUSDChange)}
            </ClickableText>
            {/* <ClickableText end={1} onClick={() => handleSort(SORT_FIELD.priceUSDChangeWeek)}>
            7d {arrow(SORT_FIELD.priceUSDChangeWeek)}
          </ClickableText> */}
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.volumeUSD)}>
              Volume 24H {arrow(SORT_FIELD.volumeUSD)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.tvlUSD)}>
              TVL {arrow(SORT_FIELD.tvlUSD)}
            </ClickableText>
          </ResponsiveGrid>

          <Break />
          {sortedTokens.map((data, i) => {
          // {SortedTokensCustom.map((data, i) => {
            if (data) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} tokenData={data} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PageButtons>
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1 ? true : false}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage ? true : false}>→</Arrow>
            </div>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
