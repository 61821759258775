/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { TYPE } from 'theme'
import { DarkGreyCard, GreyBadge } from 'components/Card'
import Loader, { LoadingRows } from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { formatDollarAmount } from 'utils/numbers'
import { PoolData } from 'state/pools/reducer'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { feeTierPercent } from 'utils'
import { Label, ClickableText } from 'components/Text'
import { PageButtons, Arrow, Break } from 'components/shared'
import { POOL_HIDE } from '../../constants/index'
import useTheme from 'hooks/useTheme'
import { networkPrefix } from 'utils/networkPrefix'
import { useActiveNetworkVersion } from 'state/application/hooks'
// import { createNextState } from '@reduxjs/toolkit'

const Wrapper = styled(DarkGreyCard)`
  width: 100%;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 20px 3.5fr repeat(3, 1fr);

  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 1.5fr repeat(2, 1fr);
    & :nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 20px 1.5fr repeat(1, 1fr);
    & :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 2.5fr repeat(1, 1fr);
    > *:nth-child(1) {
      display: none;
    }
  }
`

const LinkWrapper = styled(Link)`
  text-decoration: none;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const SORT_FIELD = {
  feeTier: 'feeTier',
  volumeUSD: 'volumeUSD',
  tvlUSD: 'tvlUSD',
  volumeUSDWeek: 'volumeUSDWeek',
}

const DataRow = ({ poolData, index }: { poolData: PoolData; index: number }) => {
  const [activeNetwork] = useActiveNetworkVersion()

  return (
    <LinkWrapper to={networkPrefix(activeNetwork) + 'pools/' + poolData.address}>
      <ResponsiveGrid>
        <Label fontWeight={400}>{index + 1}</Label>
        <Label fontWeight={400}>
          <RowFixed>
            <DoubleCurrencyLogo address0={poolData.token0.address} address1={poolData.token1.address} />
            <TYPE.label ml="8px">
              {poolData.token0.symbol}/{poolData.token1.symbol}
            </TYPE.label>
            <GreyBadge ml="10px" fontSize="14px">
              {feeTierPercent(poolData.feeTier)}
            </GreyBadge>
          </RowFixed>
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(poolData.tvlUSD)}
          {/* {formatDollarAmount(Math.random() * (990000000 - 1000000) + 1000000)} */}
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(poolData.volumeUSD)}
          {/* {formatDollarAmount(Math.random() * (350000000 - 0) + 0)} */}
        </Label>
        <Label end={1} fontWeight={400}>
          {formatDollarAmount(poolData.volumeUSDWeek)}
          {/* {formatDollarAmount(Math.random() * (350000000 - 0) + 0)} */}
        </Label>
      </ResponsiveGrid>
    </LinkWrapper>
  )
}

const MAX_ITEMS = 10

export default function PoolTable({ poolDatas, maxItems = MAX_ITEMS }: { poolDatas: PoolData[]; maxItems?: number }) {
  // theming
  const theme = useTheme()

  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.tvlUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  // const [SortedPoolsCustom, setSortedPoolsCustom] = useState([])

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    // const date = new Date()
    // date.setTime(date.getTime()+(1*24*60*60*1000))
    // const expires = date.toUTCString()

    // const decodedCookie = decodeURIComponent(document.cookie);
    // if(decodedCookie.includes("sortedPools")) {
    //   const decodedCookieArr = decodedCookie.split('; ')
    //   decodedCookieArr.forEach(decodedCookieArr_ => {
    //     const cookieName = decodedCookieArr_.split('=')
    //     if(cookieName[0] === "sortedPools") {
    //       let temp = window.localStorage.getItem("sortedPools");
    //       if(temp !== null) {
    //         let tempArr: { address: string
    //           feeTier: any
    //           liquidity: any
    //           sqrtPrice: any
    //           tick: any
    //           token0: any
    //           token1: any
    //           token0Price: any
    //           token1Price: any
    //           volumeUSD: any
    //           volumeUSDChange: any
    //           volumeUSDWeek: any
    //           tvlUSD: any
    //           tvlUSDChange: any
    //           tvlToken0: any
    //           tvlToken1: any }[] = []
    //         JSON.parse(atob(temp)).forEach((temp_: { address: string
    //           feeTier: any
    //           liquidity: any
    //           sqrtPrice: any
    //           tick: any
    //           token0: any
    //           token1: any
    //           token0Price: any
    //           token1Price: any
    //           volumeUSD: any
    //           volumeUSDChange: any
    //           volumeUSDWeek: any
    //           tvlUSD: any
    //           tvlUSDChange: any
    //           tvlToken0: any
    //           tvlToken1: any }) => {
    //           sortedPools.forEach(sortedPools_ => {
    //             if(temp_.address === sortedPools_.address) {
    //               tempArr.push(temp_)
    //             }
    //           });
    //         });
    //         let sortedPoolsTemp = tempArr.map(tempArr_ => {
    //           return Object.assign({}, tempArr_, {address: tempArr_.address,
    //           feeTier: tempArr_.feeTier,
    //           liquidity: tempArr_.liquidity,
    //           sqrtPrice: tempArr_.sqrtPrice,
    //           tick: tempArr_.tick,
    //           token0: tempArr_.token0,
    //           token1: tempArr_.token1,
    //           token0Price: tempArr_.token0Price,
    //           token1Price: tempArr_.token1Price,
    //           volumeUSD: tempArr_.volumeUSD,
    //           volumeUSDChange: tempArr_.volumeUSDChange,
    //           volumeUSDWeek: tempArr_.volumeUSDWeek,
    //           tvlUSD: tempArr_.tvlUSD,
    //           tvlUSDChange: tempArr_.tvlUSDChange,
    //           tvlToken0: tempArr_.tvlToken0,
    //           tvlToken1: tempArr_.tvlToken1});
    //         });
    //         let sortedPoolsTemp2 = JSON.stringify(sortedPoolsTemp);
    //         setSortedPoolsCustom(JSON.parse(sortedPoolsTemp2));
    //       }
    //     }
    //   });
    // }
    // else {
    //   let sortedPoolsTemp = sortedPools.map(sortedPools_ => {
    //     return Object.assign({}, sortedPools_, {address: sortedPools_.address,
    //     feeTier: sortedPools_.feeTier,
    //     liquidity: sortedPools_.liquidity,
    //     sqrtPrice: sortedPools_.sqrtPrice,
    //     tick: sortedPools_.tick,
    //     token0: sortedPools_.token0,
    //     token1: sortedPools_.token1,
    //     token0Price: sortedPools_.token0Price,
    //     token1Price: sortedPools_.token1Price,
    //     volumeUSD: Math.random() * (350000000 - 0) + 0,
    //     volumeUSDChange: Math.random() * (10 + 0.01) - 0.01,
    //     volumeUSDWeek: Math.random() * (350000000 - 0) + 0,
    //     tvlUSD: Math.random() * (990000000 - 1000000) + 1000000,
    //     tvlUSDChange: Math.random() * (10 + 0.01) - 0.01,
    //     tvlToken0: sortedPools_.tvlToken0,
    //     tvlToken1: sortedPools_.tvlToken1});
    //   });
    //   if(sortedPoolsTemp.length > 0) {
    //     window.localStorage.removeItem("sortedPools");
    //     window.localStorage.setItem("sortedPools", btoa(JSON.stringify(sortedPoolsTemp)));
    //     document.cookie = "sortedPools=true; expires="+expires;
    //   }
    //   let sortedPoolsTemp2 = JSON.stringify(sortedPoolsTemp);
    //   setSortedPoolsCustom(JSON.parse(sortedPoolsTemp2));
    // }


    let extraPages = 1
    if (poolDatas.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(poolDatas.length / maxItems) + extraPages)
  }, [maxItems, poolDatas])

  const sortedPools = useMemo(() => {
    return poolDatas
      ? poolDatas
          .filter((x) => !!x && !POOL_HIDE.includes(x.address))
          .sort((a, b) => {
            if (a && b) {
              return a[sortField as keyof PoolData] > b[sortField as keyof PoolData]
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [maxItems, page, poolDatas, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!poolDatas) {
    return <Loader />
  }

  return (
    <Wrapper>
      {sortedPools.length > 0 ? (
      // {SortedPoolsCustom.length > 0 ? (
        <AutoColumn gap="16px">
          <ResponsiveGrid>
            <Label color={theme.text2}>#</Label>
            <ClickableText color={theme.text2} onClick={() => handleSort(SORT_FIELD.feeTier)}>
              Pool {arrow(SORT_FIELD.feeTier)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.tvlUSD)}>
              TVL {arrow(SORT_FIELD.tvlUSD)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.volumeUSD)}>
              Volume 24H {arrow(SORT_FIELD.volumeUSD)}
            </ClickableText>
            <ClickableText color={theme.text2} end={1} onClick={() => handleSort(SORT_FIELD.volumeUSDWeek)}>
              Volume 7D {arrow(SORT_FIELD.volumeUSDWeek)}
            </ClickableText>
          </ResponsiveGrid>
          <Break />
          {sortedPools.map((poolData, i) => {
          // {SortedPoolsCustom.map((poolData, i) => {
            if (poolData) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} poolData={poolData} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PageButtons>
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1 ? true : false}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage ? true : false}>→</Arrow>
            </div>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
