// import React, { useState, useEffect } from 'react'
import React from 'react'
import styled from 'styled-components'
import { RowBetween, RowFixed, AutoRow } from 'components/Row'
import { TYPE, ExternalLink } from 'theme'
import { useEthPrices } from 'hooks/useEthPrices'
import { formatDollarAmount } from 'utils/numbers'
import Polling from './Polling'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.black};
  padding: 10px 20px;
`

const Item = styled(TYPE.main)`
  font-size: 12px;
  color: ${({ theme }) => theme.text1};
`

const StyledLink = styled(ExternalLink)`
  font-size: 12px;
  color: ${({ theme }) => theme.text1};
`

const TopBar = () => {
  const ethPrices = useEthPrices()
  // const [ethPricesCurrent, setethPricesCurrent] = useState<number | undefined>()


  // useEffect(() => {
  //   const date = new Date()
  //   date.setTime(date.getTime()+(1*24*60*60*1000))
  //   const expires = date.toUTCString()

  //   const ethPricesCurrentTemp = Math.random() * (1550 - 1525) + 1525

  //   const decodedCookie = decodeURIComponent(document.cookie);
  //   if(decodedCookie.includes("ethPricesCurrent")) {
  //     const decodedCookieArr = decodedCookie.split('; ')
  //     decodedCookieArr.forEach(decodedCookieArr_ => {
  //       const cookieName = decodedCookieArr_.split('=')
  //       if(cookieName[0] === "ethPricesCurrent") {
  //         setethPricesCurrent(parseInt(cookieName[1]))
  //       }
  //     });
  //   }
  //   else {
  //     document.cookie = "ethPricesCurrent="+ethPricesCurrentTemp+"; expires="+expires;
  //     setethPricesCurrent(ethPricesCurrentTemp)
  //   }
  // }, [])


  return (
    <Wrapper>
      <RowBetween>
        <Polling />
        <AutoRow gap="6px">
          <RowFixed>
            <Item>ETH Price:</Item>
            <Item fontWeight="700" ml="4px">
              {formatDollarAmount(ethPrices?.current)}
              {/* {formatDollarAmount(ethPricesCurrent)} */}
            </Item>
          </RowFixed>
        </AutoRow>
        <AutoRow gap="6px" style={{ justifyContent: 'flex-end' }}>
          {/* <StyledLink href="https://v2.info.uniswap.org/#/">V2 Analytics</StyledLink>
          <StyledLink href="https://docs.uniswap.org/">Docs</StyledLink> */}
          <StyledLink href="https://app.veniceswap.com/">App</StyledLink>
        </AutoRow>
      </RowBetween>
    </Wrapper>
  )
}

export default TopBar
