/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable prefer-const */
import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import { ResponsiveRow, RowBetween, RowFixed } from 'components/Row'
import LineChart from 'components/LineChart/alt'
import useTheme from 'hooks/useTheme'
import { useProtocolData, useProtocolChartData, useProtocolTransactions } from 'state/protocol/hooks'
import { DarkGreyCard } from 'components/Card'
import { formatDollarAmount } from 'utils/numbers'
import Percent from 'components/Percent'
import { HideMedium, HideSmall, StyledInternalLink } from '../../theme/components'
import TokenTable from 'components/tokens/TokenTable'
import PoolTable from 'components/pools/PoolTable'
import { PageWrapper, ThemedBackgroundGlobal } from 'pages/styled'
import { unixToDate } from 'utils/date'
import BarChart from 'components/BarChart/alt'
import { useAllPoolData } from 'state/pools/hooks'
import { notEmpty } from 'utils'
import TransactionsTable from '../../components/TransactionsTable'
import { useAllTokenData } from 'state/tokens/hooks'
import { MonoSpace } from 'components/shared'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { useTransformedVolumeData } from 'hooks/chart'
import { SmallOptionButton } from 'components/Button'
import { VolumeWindow } from 'types'
// import { setTimeout } from 'timers'

const ChartWrapper = styled.div`
  width: 49%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const theme = useTheme()

  const [activeNetwork] = useActiveNetworkVersion()

  const [protocolData] = useProtocolData()
  const [chartData] = useProtocolChartData()
  const [transactions] = useProtocolTransactions()
  
  // const [protocolDataVolumeUSD, setprotocolDataVolumeUSD] = useState<number | undefined>()
  // const [protocolDataVolumeUSDChange, setprotocolDataVolumeUSDChange] = useState<number | undefined>()
  // const [protocolDataFeesUSD, setprotocolDataFeesUSD] = useState<number | undefined>()
  // const [protocolDataFeeChange, setprotocolDataFeeChange] = useState<number | undefined>()
  // const [protocolDataTvlUSD, setprotocolDataTvlUSD] = useState<number | undefined>()
  // const [protocolDataTvlUSDChange, setprotocolDataTvlUSDChange] = useState<number | undefined>()

  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
  const [leftLabel, setLeftLabel] = useState<string | undefined>()
  const [rightLabel, setRightLabel] = useState<string | undefined>()

  useEffect(() => {
    setLiquidityHover(undefined)
    setVolumeHover(undefined)
    // const date = new Date()
    // date.setTime(date.getTime()+(1*24*60*60*1000))
    // const expires = date.toUTCString()

    // let protocolDataVolumeUSDTemp = Math.random() * (181692 - 180692) + 180692
    // let protocolDataVolumeUSDChangeTemp = Math.random() * (30 + 30) - 30
    // let protocolDataFeesUSDTemp = Math.random() * (420000 - 405000) + 405000
    // let protocolDataFeeChangeTemp = Math.random() * (30 + 30) - 30
    // let protocolDataTvlUSDTemp = Math.random() * (1197000 - 1196000) + 1196000
    // let protocolDataTvlUSDChangeTemp = Math.random() * (30 + 30) - 30
    // if(protocolDataTvlUSDTemp < protocolDataVolumeUSDTemp) {
    //   protocolDataVolumeUSDTemp = Math.random() * (181692 - 180692) + 180692
    // }

    // const decodedCookie = decodeURIComponent(document.cookie);
    // if(decodedCookie.includes("protocolDataVolumeUSD")) {
    //   const decodedCookieArr = decodedCookie.split('; ')
    //   decodedCookieArr.forEach(decodedCookieArr_ => {
    //     const cookieName = decodedCookieArr_.split('=')
    //     if(cookieName[0] === "protocolDataVolumeUSD") {
    //       setprotocolDataVolumeUSD(parseInt(cookieName[1]))
    //       if (volumeHover === undefined && protocolData) {
    //         setVolumeHover(parseInt(cookieName[1]))
    //       }
    //     }

    //     if(cookieName[0] === "protocolDataVolumeUSDChange") {
    //       setprotocolDataVolumeUSDChange(parseInt(cookieName[1]))
    //       if (volumeHover === undefined && protocolData) {
    //         setVolumeHover(parseInt(cookieName[1]))
    //       }
    //     }

    //     if(cookieName[0] === "protocolDataFeesUSD") {
    //       setprotocolDataFeesUSD(parseInt(cookieName[1]))
    //       if (volumeHover === undefined && protocolData) {
    //         setVolumeHover(parseInt(cookieName[1]))
    //       }
    //     }

    //     if(cookieName[0] === "protocolDataFeeChange") {
    //       setprotocolDataFeeChange(parseInt(cookieName[1]))
    //       if (volumeHover === undefined && protocolData) {
    //         setVolumeHover(parseInt(cookieName[1]))
    //       }
    //     }

    //     if(cookieName[0] === "protocolDataTvlUSD") {
    //       setprotocolDataTvlUSD(parseInt(cookieName[1]))
    //       if (liquidityHover === undefined && protocolData) {
    //         setLiquidityHover(parseInt(cookieName[1]))
    //       }
    //     }

    //     if(cookieName[0] === "protocolDataTvlUSDChange") {
    //       setprotocolDataTvlUSDChange(parseInt(cookieName[1]))
    //       if (volumeHover === undefined && protocolData) {
    //         setVolumeHover(parseInt(cookieName[1]))
    //       }
    //     }
    //   })
    // }
    // else {
    //   document.cookie = "protocolDataVolumeUSD="+protocolDataVolumeUSDTemp+"; expires="+expires;
    //   setprotocolDataVolumeUSD(protocolDataVolumeUSDTemp)
    //   if (volumeHover === undefined && protocolData) {
    //     setVolumeHover(protocolDataVolumeUSDTemp)
    //   }

    //   document.cookie = "protocolDataVolumeUSDChange="+protocolDataVolumeUSDChangeTemp+"; expires="+expires;
    //   setprotocolDataVolumeUSDChange(protocolDataVolumeUSDChangeTemp)
    //   if (volumeHover === undefined && protocolData) {
    //     setVolumeHover(protocolDataVolumeUSDChangeTemp)
    //   }

    //   document.cookie = "protocolDataFeesUSD="+protocolDataFeesUSDTemp+"; expires="+expires;
    //   setprotocolDataFeesUSD(protocolDataFeesUSDTemp)
    //   if (volumeHover === undefined && protocolData) {
    //     setVolumeHover(protocolDataFeesUSDTemp)
    //   }
      
    //   document.cookie = "protocolDataFeeChange="+protocolDataFeeChangeTemp+"; expires="+expires;
    //   setprotocolDataFeeChange(protocolDataFeeChangeTemp)
    //   if (volumeHover === undefined && protocolData) {
    //     setVolumeHover(protocolDataFeeChangeTemp)
    //   }
      
    //   document.cookie = "protocolDataTvlUSD="+protocolDataTvlUSDTemp+"; expires="+expires;
    //   setprotocolDataTvlUSD(protocolDataTvlUSDTemp)
    //   if (liquidityHover === undefined && protocolData) {
    //     setLiquidityHover(protocolDataTvlUSDTemp)
    //   }

    //   document.cookie = "protocolDataTvlUSDChange="+protocolDataTvlUSDChangeTemp+"; expires="+expires;
    //   setprotocolDataTvlUSDChange(protocolDataTvlUSDChangeTemp)
    //   if (volumeHover === undefined && protocolData) {
    //     setVolumeHover(protocolDataTvlUSDChangeTemp)
    //   }
    // }
  }, [activeNetwork])

  // get all the pool datas that exist
  const allPoolData = useAllPoolData()
  const poolDatas = useMemo(() => {
    return Object.values(allPoolData)
      .map((p) => p.data)
      .filter(notEmpty)
  }, [allPoolData])

  // if hover value undefined, reset to current day value
  useEffect(() => {
    if (volumeHover === undefined && protocolData) {
      setVolumeHover(protocolData.volumeUSD)
      // setVolumeHover(protocolDataVolumeUSD)
    }
  }, [protocolData, volumeHover])
  useEffect(() => {
    if (liquidityHover === undefined && protocolData) {
      setLiquidityHover(protocolData.tvlUSD)
      // setLiquidityHover(protocolDataTvlUSD)
    }
  }, [liquidityHover, protocolData])

  const formattedTvlData = useMemo(() => {
    if (chartData) {
      // // eslint-disable-next-line prefer-const
      // let chartData2 = []
      // // eslint-disable-next-line prefer-const
      // let tvlUSDTemp = Math.random() * 1000000  // Math.random() * 1000000000
      // // eslint-disable-next-line prefer-const
      // let counter = 1
      // for (let i = 0; i < chartData.length; i++) {
      //   if(counter === 5) {
      //     tvlUSDTemp += Math.random() * 10000
      //     counter = 1
      //   }
      //   counter++
      //   // eslint-disable-next-line prefer-const
      //   let temp = {
      //     date: chartData[i].date,
      //     tvlUSD: tvlUSDTemp,
      //     volumeUSD: chartData[i].volumeUSD
      //   }
      //   chartData2.push(temp)
      // }

      // return chartData2.map((day) => {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.tvlUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])

  const formattedVolumeData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.volumeUSD,
          // value: Math.random() * 1000000000,
        }
      })
    } else {
      return []
    }
  }, [chartData])

  const weeklyVolumeData = useTransformedVolumeData(chartData, 'week')
  const monthlyVolumeData = useTransformedVolumeData(chartData, 'month')

  const allTokens = useAllTokenData()

  const formattedTokens = useMemo(() => {
    return Object.values(allTokens)
      .map((t) => t.data)
      .filter(notEmpty)
  }, [allTokens])

  const [volumeWindow, setVolumeWindow] = useState(VolumeWindow.daily)

  return (
    <PageWrapper>
      <ThemedBackgroundGlobal backgroundColor={activeNetwork.bgColor} />
      <AutoColumn gap="16px">
        <TYPE.main style={{color: 'white'}}>Veniceswap Overview</TYPE.main>
        <ResponsiveRow>
          <ChartWrapper>
            <LineChart
              data={formattedTvlData}
              height={220}
              minHeight={332}
              color={activeNetwork.primaryColor}
              value={liquidityHover}
              label={leftLabel}
              setValue={setLiquidityHover}
              setLabel={setLeftLabel}
              topLeft={
                <AutoColumn gap="4px">
                  <TYPE.mediumHeader fontSize="16px" >TVL</TYPE.mediumHeader>
                  <TYPE.largeHeader fontSize="32px">
                    <MonoSpace>{formatDollarAmount(liquidityHover, 2, true)} </MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {leftLabel ? <MonoSpace>{leftLabel} (UTC)</MonoSpace> : null}
                  </TYPE.main>
                </AutoColumn>
              }
            />
          </ChartWrapper>
          <ChartWrapper>
            <BarChart
              height={220}
              minHeight={332}
              data={
                volumeWindow === VolumeWindow.monthly
                  ? monthlyVolumeData
                  : volumeWindow === VolumeWindow.weekly
                  ? weeklyVolumeData
                  : formattedVolumeData
              }
              color={theme.blue1}
              setValue={setVolumeHover}
              setLabel={setRightLabel}
              value={volumeHover}
              label={rightLabel}
              activeWindow={volumeWindow}
              topRight={
                <RowFixed style={{ marginLeft: '-40px', marginTop: '8px' }}>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.daily}
                    onClick={() => setVolumeWindow(VolumeWindow.daily)}
                  >
                    D
                  </SmallOptionButton>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.weekly}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.weekly)}
                  >
                    W
                  </SmallOptionButton>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.monthly}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.monthly)}
                  >
                    M
                  </SmallOptionButton>
                </RowFixed>
              }
              topLeft={
                <AutoColumn gap="4px">
                  <TYPE.mediumHeader fontSize="16px">Volume 24H</TYPE.mediumHeader>
                  <TYPE.largeHeader fontSize="32px">
                    <MonoSpace> {formatDollarAmount(volumeHover, 2)}</MonoSpace>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {rightLabel ? <MonoSpace>{rightLabel} (UTC)</MonoSpace> : null}
                  </TYPE.main>
                </AutoColumn>
              }
            />
          </ChartWrapper>
        </ResponsiveRow>
        <HideSmall>
          <DarkGreyCard>
            <RowBetween>
              <RowFixed>
                <RowFixed mr="20px">
                  <TYPE.main mr="4px">Volume 24H: </TYPE.main>
                  <TYPE.label mr="4px">{formatDollarAmount(protocolData?.volumeUSD)}</TYPE.label>
                  <Percent value={protocolData?.volumeUSDChange} wrap={true} />
                  {/* <TYPE.label mr="4px">{formatDollarAmount(protocolDataVolumeUSD)}</TYPE.label>
                  <Percent value={protocolDataVolumeUSDChange} wrap={true} /> */}
                </RowFixed>
                <RowFixed mr="20px">
                  <TYPE.main mr="4px">Fees 24H: </TYPE.main>
                  <TYPE.label mr="4px">{formatDollarAmount(protocolData?.feesUSD)}</TYPE.label>
                  <Percent value={protocolData?.feeChange} wrap={true} />
                  {/* <TYPE.label mr="4px">{formatDollarAmount(protocolDataFeesUSD)}</TYPE.label>
                  <Percent value={protocolDataFeeChange} wrap={true} /> */}
                </RowFixed>
                <HideMedium>
                  <RowFixed mr="20px">
                    <TYPE.main mr="4px">TVL: </TYPE.main>
                    <TYPE.label mr="4px">{formatDollarAmount(protocolData?.tvlUSD)}</TYPE.label>
                    {/* <TYPE.label mr="4px">{formatDollarAmount(protocolDataTvlUSD)}</TYPE.label> */}
                    <TYPE.main></TYPE.main>
                    <Percent value={protocolData?.tvlUSDChange} wrap={true} />
                    {/* <Percent value={protocolDataTvlUSDChange} wrap={true} /> */}
                  </RowFixed>
                </HideMedium>
              </RowFixed>
            </RowBetween>
          </DarkGreyCard>
        </HideSmall>
        <RowBetween>
          <TYPE.main>Top Tokens</TYPE.main>
          <StyledInternalLink to="tokens">Explore</StyledInternalLink>
        </RowBetween>
        <TokenTable tokenDatas={formattedTokens} />
        <RowBetween>
          <TYPE.main>Top Pools</TYPE.main>
          <StyledInternalLink to="pools">Explore</StyledInternalLink>
        </RowBetween>
        <PoolTable poolDatas={poolDatas} />
        <RowBetween>
          <TYPE.main>Transactions</TYPE.main>
        </RowBetween>
        {transactions ? <TransactionsTable transactions={transactions} color={activeNetwork.primaryColor} /> : null}
      </AutoColumn>
    </PageWrapper>
  )
}
